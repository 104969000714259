import { useEffect, useState } from 'react'
import { Video as VideoEntity } from '@mpe/api-client/models/entities/video'
import Icon from '@/components/Icon'
import VideoPlayer from '@/components/VideoPlayer'
import SingleTileImage from '@/components/Tiles/SingleTileImage'
import { getTileImageProps } from '@/utils/image'

import imageClasses from '@/components/Tiles/singleTileImage.module.css'
import { BaseSectionItem } from '@/types/homeContent.model'

export interface VideoProps extends BaseSectionItem {
  isVisible: boolean
  hideTitle?: boolean
}

const Video = (props: VideoProps) => {
  const [play, setPlay] = useState(false)

  const handleClick = () => {
    setPlay(true)
  }

  useEffect(() => {
    setPlay(false)
  }, [props.isVisible])

  return (
    <div className="video w-full">
      {props.isVisible && play ? (
        <Player {...props} />
      ) : (
        <Cover onClick={handleClick} item={props} hideTitle={props.hideTitle} />
      )}
    </div>
  )
}

interface CoverProps {
  onClick?: () => void
  item: BaseSectionItem
  hideTitle?: boolean
}

const Cover = ({ onClick, item, hideTitle }: CoverProps) => {
  return (
    <div className="video_cover w-full h-full relative">
      <div className="video_cover__image relative" style={{ paddingBottom: '56.25%' }}>
        <SingleTileImage {...getTileImageProps('cover', item.entity)} classes={imageClasses} />
      </div>
      <div className="video_cover__container absolute w-full h-full top-0 z-10">
        <a
          onClick={onClick}
          className="flex flex-column justify-center items-center w-full h-full cursor-pointer"
        >
          <div className="text-center flex-child">
            <Icon
              name="play-bold-white"
              className="w-12 h-12 sm:w-24 sm:h-24 xl:w-32 xl:h-32 mb-4 inline-block"
            ></Icon>
            {!hideTitle && (
              <>
                <h4 className="video__name uppercase font-roboto font-extrabold text-lg md:text-xl mb-2">
                  {item.subtitle}
                </h4>
                <h3 className="video__title font-roboto font-extrabold text-2xl md:text-4xl">
                  {item.title}
                </h3>
              </>
            )}
          </div>
        </a>
      </div>
    </div>
  )
}

const Player = (props: BaseSectionItem) => {
  return (
    <div className="video_player w-full h-full">
      <div className="video_player__container">
        {props.entity && (
          <VideoPlayer
            autoplay={true}
            classNames="md:w-full 2xl:w-full w-full h-full m-auto"
            entity={props.entity as VideoEntity}
          />
        )}
      </div>
    </div>
  )
}

export default Video
