import { useEffect } from 'react'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { getHomePageContent } from '@/fetchers'
import Layout from '@/components/Layout'
import BlogHeader from '@/components/BlogHeader'
import ArticleList from '@/components/ArticleList'
import Search from '@/components/Search'
import BannerMpeArchive from '@/components/BannerMpeArchive'
import Numbers from '@/components/Numbers'
import Carousel from '@/components/Carousel/Carousel'
import { CarouselProps } from '@/components/Carousel/types'
import Collection from '@/components/Collection'
import { CollectionProps } from '@/components/Collection/types'
import BannerFollowIndex from '@/components/BannerFollowIndex'
import { HomeContentModel } from '@/types/homeContent.model'
import VideoBox from '@/components/HomePage/VideoBox/VideoBox'
import getConfig from 'next/config'
import { bannerFestivalCollection } from '@/consts/bannerFestivalCollection'
import BannerFestival from '@/components/BannerFestival'
import { TikTokEmbed } from '@/components/TikTokEmbed'
import VideoLyrics, { VideoLyricsProps } from '@/components/VideoLyrics'
import NewsLetter from '@/components/NewsLetter'
import SearchBar from '@/components/SearchBar'
import BannerMpe from '@/components/BannerMpe'
import RandomVideosPlayer from '@/components/RandomVideosPlayer'

const collectionsOrder = ['NEWEST_ARTIST_COLLECTION', 'GENRE_COLLECTION', 'ERA_COLLECTION']

export default function Index(props: any) {
  const router = useRouter()
  useEffect(() => {
    if (!props.content || Object.keys(props.content).length === 0) {
      router.push('/eloado/1')
    }
  })

  const title = 'Popkulturális'
  const content: HomeContentModel = props.content || {}
  const fbAppId = getConfig().publicRuntimeConfig?.fbAppId || null

  // order collections
  const orderedCollections: CollectionProps[] = []
  Object.entries(content.collections || {}).forEach(([key, collection]) => {
    const collectionProp = collection as CollectionProps
    const inOrder = collectionsOrder.includes(key)

    if (!orderedCollections.length || !inOrder) {
      orderedCollections.push(collectionProp)
    } else if (inOrder) {
      const newIndex = collectionsOrder.indexOf(key)
      orderedCollections.splice(newIndex, 0, collectionProp)
    }
  })

  return (
    <>
      <Head>
        <title>{title}</title>
        {fbAppId && (
          <>
            <meta property="og:url" content={content.meta?.url || '/'} />
            <meta property="og:type" content={content.meta?.type || 'website'} />
            <meta
              property="og:title"
              content={content.meta?.title || 'Magyar Popkulturalis Értéktár'}
            />
            <meta property="og:description" content={content.meta?.description || ''} />
            <meta property="og:image" content={content.meta?.image || ''} />
            <meta property="fb:app_id" content={fbAppId} />
          </>
        )}
      </Head>
      <div className="min-w-[320px]">
        <Layout>
          <div className="container-padded mt-16 xl:mt-24">
            <SearchBar showTitle />
          </div>
          <div className="">
            <div className="bg-ellipse-left bg-no-repeat bg-left-top">
              <BannerMpe />
              {content.numbers && content.numbers.items && (
                <Numbers items={content.numbers.items} />
              )}
              {content.randomVideosPlayer && <RandomVideosPlayer {...content.randomVideosPlayer} />}
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

export async function getServerSideProps({ res }: GetServerSidePropsContext) {
  res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')

  const contentEntity: any = await getHomePageContent()

  return {
    props: { content: contentEntity?.data || null }
  }
}
