import { BaseSectionItem } from '@/types/homeContent.model'
import Video from '@/components/Video/Video'
import { getEntityUrl } from '@/utils'
import { Link } from '../Link/Link'

export interface RandomVideosPlayerProps {
  title: string
  items: BaseSectionItem[]
}

const RandomVideosPlayer = (props: RandomVideosPlayerProps) => {
  return (
    <div className="video-box pt-16 pb-16 flex justify-center">
      <div className=" w-full lg:w-3/4">
        <div className="container-padded">
          <h2 className="font-gilroy font-extrabold text-4xl sm:text-5xl text-center max-w-2xl ml-auto mr-auto mb-10">
            {props.title}
          </h2>

          <div className="grid grid-cols-1 gap-16 mt-8 max-w-screen-lg mx-auto">
            {props.items.map((item, i) => (
              <div key={i}>
                <div className="video-box__item">
                  <Video {...item} hideTitle isVisible={true} />
                </div>
                <h4 className="mt-4">
                  <Link
                    href={getEntityUrl(item.entity)}
                    className="font-semibold text-center  text-2xl lg:text-4xl underline"
                  >
                    {item.entity.name}
                  </Link>
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RandomVideosPlayer
