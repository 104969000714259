const BannerMpe = () => {
  return (
    <div className="banner-mpe-archive mt-12 pt-12">
      <div className="banner-mpe-archive__container container-padded">
        <div className="banner-mpe-archive__title">
          <h2 className="font-gilroy text-5xl font-extrabold max-w-2/3 lg:max-w-1/2">
            A legnagyobb magyar popkulturális gyűjtemény
          </h2>
        </div>
      </div>
    </div>
  )
}

export default BannerMpe
