import { useState } from 'react'
import { useRouter } from 'next/router'
import { SEARCH_CATEGORIES } from '@/consts'
import useDataLayer from '@/hooks/useDataLayer'
import Icon from '../Icon'

interface SearchBarProps {
  initialSearch?: string
  initialCategory?: string
  showTitle?: boolean
  autoFocus?: boolean
}

export default function SearchBar({
  initialSearch = '',
  initialCategory = 'entities',
  showTitle = false,
  autoFocus = false
}: SearchBarProps) {
  const [search, setSearch] = useState(initialSearch)
  const [categoryUrl, setCategoryUrl] = useState(initialCategory)
  const router = useRouter()
  const dataLayer = useDataLayer()

  const onSearch = () => {
    if (search && search.trim()) {
      dataLayer.push({
        event: 'search',
        value: search
      })

      const initialPage = 1
      router.push(
        `/kereses/${categoryUrl}?search=${encodeURIComponent(search)}&page=${initialPage}`
      )
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') onSearch()
  }

  const onCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCategory = e.target.value
    setCategoryUrl(newCategory)

    dataLayer.push({
      event: 'set-category',
      value: newCategory
    })
  }

  return (
    <div className="w-full mt-16">
      {showTitle && (
        <h2 className="mt-3 md:mt-5 text-3xl lg:text-5xl font-bold tracking-wide">Keresés</h2>
      )}
      <div className="md:flex items-end mt-6">
        <div className="relative w-48 mr-14">
          <Icon
            name="caret"
            className="absolute flex items-center w-4 right-0 top-0 h-full mr-5 pointer-events-none"
          />
          <select
            className="appearance-none bg-black rounded-full px-5 py-2 border-white border-2 focus:outline-none w-full cursor-pointer"
            onChange={onCategoryChange}
            value={categoryUrl}
          >
            <option key="all" value="entities">
              Összes kategória
            </option>
            {Object.entries(SEARCH_CATEGORIES).map(([catName, catUrl]) => (
              <option key={catName} value={catUrl}>
                {catName}
              </option>
            ))}
          </select>
        </div>
        <input
          autoFocus={autoFocus}
          type="text"
          value={search}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          className="bg-transparent border-b-2 border-white outline-none focus:border-skyBlue mt-10 md:mt-5 mr-7 md:mr-14 w-full md:w-1/2 py-2 mb-4 md:mb-0 text-lg md:text-3xl"
          placeholder="Írj be egy vagy több kulcsszót!"
        />
        <button
          className="px-9 py-2 bg-gradient-to-tr from-white to-paleYellow hover:from-paleYellow hover:to-white transition-all text-black font-bold rounded-full focus:outline-none"
          onClick={onSearch}
        >
          Keresés
        </button>
      </div>
    </div>
  )
}
